import React, { useEffect, useState } from "react";
import "./authNavbar.css";
import profileIcon from "../../img/profileIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAuth,
  updateOrganisation,
  updateUserDetails,
  updateWorkspace,
} from "../../redux/feature/user";
import CreateOrg from "../organisation/CreateOrg";
import { getData } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import iconUser from "../../img/icon_user.svg";


const AuthNavbar = () => {
  const [openProfile, setOpenProfile] = useState(false);
  const [organisation, setOrganisation] = useState(
    useSelector((state) => state.user.organisation)
  );
  const userDetails = useSelector((state) => state.user.userDetails);
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (organisation.name == "") {
      const getOrganisation = async () => {
        const response = await getData("organisations");
        if (!response.orgs?.length) setShowPopup(true);
        else {
          console.log(response);
          dispatch(
            updateOrganisation({
              id: response.orgs[0].id,
              name: response.orgs[0].name,
            })
          );
          dispatch(
            updateWorkspace({
              id: response.orgs[0].workspace_id,
              name: response.orgs[0].workspace_name,
            })
          );
          setOrganisation({
            id: response.orgs[0].id,
            name: response.orgs[0].name,
          });
        }
      };
      if (!showPopup) getOrganisation();
    }
  }, [showPopup]);

  const handleLogout = async () => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/server/logout`, {
        credentials: "include",
      });
      dispatch(updateAuth(false));
      dispatch(updateUserDetails({}));
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const handleOutsideClick = () => {
      if (openProfile) {
        setOpenProfile(false);
      }
    };

    if (openProfile) {
      console.log("added");
      document.addEventListener("click", handleOutsideClick);
    } else {
      console.log("removed");
      document.removeEventListener("click", handleOutsideClick);
    }
  }, [openProfile]);

  return (
    <>
      {showPopup && <CreateOrg setShowPopup={setShowPopup} />}
      <div className="authNavbar">
        <div className="authNavbarLinks">
          {/* <span style={{width: "170px"}}>{organisation.name || "Organisation X"}</span> */}
          <span
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Project Management
          </span>
          <span
            onClick={() => {
              navigate("/invoicing");
            }}
          >
            Invoicing
          </span>
        </div>
        <div className="authNavbarProfile">
          <img src={userDetails.icon ?? iconUser} onClick={(e) => {setOpenProfile(!openProfile); e.stopPropagation()}} />
          {openProfile && (
            <div
              className="authNavbarProfileMenu"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="authNavbarProfileMenuHeading">Account</div>
              <div className="authNavbarProfileMenuUser">
                <img
                  src={userDetails.icon ?? iconUser}
                  className="authNavbarProfileMenuUserImage"
                />
                <div className="authNavbarProfileMenuUserDetails">
                  <span className="authNavbarProfileMenuUserName">
                    {userDetails.name}
                  </span>
                  {userDetails.email && (
                    <span className="authNavbarProfileMenuUserEmail">
                      {userDetails.email}
                    </span>
                  )}
                </div>
              </div>
              <hr className="authNavbarProfileMenuDivider" />
              <div className="authNavbarProfileMenuList">
                <span>
                  <a href="/profile">Personal Profile</a>
                </span>
                {/* <span>Settings</span>
                <span>Notifications</span> */}
                <span onClick={handleLogout}>Log out</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AuthNavbar;
