import React from "react";
import styles from "./footer.module.css";
import figmaIcon from "../../img/figma-1 1.svg";
import { IoLogoFacebook } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";

function Footer() {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerRow}>
        <div className={styles.footerCol}>
          <ul>
            <li>
              <a href="/about">Company</a>
            </li>
            <li>
              <a href="/about">About Us</a>
            </li>
            <li>
              <a href="/about">Why Choose Us</a>
            </li>
            <li>
              <a href="/pricing">Pricing</a>
            </li>
            <li>
              <a href="/testimonial">Testimonials</a>
            </li>
          </ul>
        </div>
        <div className={styles.footerCol}>
          <ul>
            <li>Resources</li>
            <li>Privacy Policy</li>
            <li>
              <a href="/terms">Terms and Condition</a>
            </li>
            <li>Blog</li>
            <li>
              <a href="/contact-us">Contact Us</a>
            </li>
          </ul>
        </div>
        <div className={styles.footerCol}>
          <ul>
            <li>Product</li>
            <li>Project Management</li>
            <li>Time tracker</li>
            <li>Time schedule</li>
            <li>Collaborations</li>
          </ul>
        </div>
        <div className={`${styles.footerCol} ${styles.newsletter}`}>
          <h1>
            <a href="/">
              <img src={figmaIcon} />
              Backdoor
            </a>
          </h1>
          <p>Subscribe to our Newsletter</p>
          <div className={styles.subscribe}>
            <input type="text" placeholder="Enter your Email" />
            <button>Subscribe</button>
          </div>
        </div>
      </div>
      <div className={`${styles.footerRow} ${styles.mt}`}>
        <div className={styles.line}></div>
        <span>Copyright@2024</span>
        <div className={styles.socials}>
          <span>
            <IoLogoFacebook />
          </span>
          <span>
            <FaInstagram />
          </span>
          <span>
            <FaXTwitter />
          </span>
          <span>
            <FaLinkedin />
          </span>
        </div>
        <div className={styles.line}></div>
      </div>
    </div>
  );
}

export default Footer;
