import React, { useState } from "react";
import "./createWorkspace.css";
import { useSelector, useDispatch } from "react-redux";
import Confetti from "react-confetti";
import { updateWorkspace } from "../../redux/feature/user"; // Ensure this action is correctly imported
import { postData } from "../../utils/api";
import { RxCross2 } from "react-icons/rx";
import { CgClose } from "react-icons/cg";

const CreateWorkspace = ({ setShowPopup }) => {
  const [workspace, setWorkspace] = useState("Workspace_1");
  const [createOrg, setCreateOrg] = useState(true);
  const userDetails = useSelector((state) => state.user.userDetails);
  const organisation = useSelector((state) => state.user.organisation);
  const dispatch = useDispatch();
  const width = window.outerWidth;
  const height = window.outerHeight;

  const handelSave = async () => {
    const workspaceData = await postData("create-workspace", {
      orgId: organisation.id,
      workspaceName: workspace,
    });

    dispatch(
      updateWorkspace({ id: workspaceData.id, name: workspaceData.name })
    );

    setCreateOrg(false);
  };

  return (
    <>
      <div className="containerOverlay">
        {!createOrg && <Confetti width={width} height={height} />}
      </div>
      <div className="workspaceScreen">
        <div className="popupTitle">
          <h1>{organisation.name}</h1>
          <CgClose
            className="workspacePopupCancelIcon"
            onClick={() => setShowPopup(false)}
          />
        </div>
        {createOrg ? (
          <div className="workspaceInnerContainer">
            <div>
              <h2 className="workspaceQuestion">
                What would you like to call your new workspace?
              </h2>
              <input
                className="workspaceInput"
                type="text"
                value={workspace}
                onChange={(e) => setWorkspace(e.target.value)}
              />
            </div>

            <div className="workspaceAction">
              <button className="" onClick={handelSave}>
                Save
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="workspaceInnerContainer">
              <div className="workspaceContent">
                <h2 className="workspaceContentHeading">
                  Congrats {userDetails?.name}! You made it
                </h2>
                <h2 className="workspaceContentHeading">
                  Welcome to your new workspace
                </h2>
                <ul>
                  <li>Share content with brands and clients</li>
                  <li>Assign content to campaigns</li>
                  <li>Auto post and repurpose content</li>
                  <li>Generate custom captions and hashtags</li>
                </ul>
              </div>

              <div className="workspaceAction">
                <button className="" onClick={() => setShowPopup(false)}>
                  Start exploring
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CreateWorkspace;
