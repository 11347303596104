import React from "react";
import "./navbar.css";
import figmaIcon from "../../img/figma-1 1.svg";

const Navbar = () => {
  return (
    <>
      <div className="navbar">
        <ul className="navbarNavigation">
          <li className="navbarCompany">
            <img className="navbarLogo" src={figmaIcon} alt="" />
            <h3>
              <a href="/">Backdoor</a>
            </h3>
          </li>
          <>
            <li>
              <a href="/testimonial">Testimonials</a>
            </li>
            <li>
              <a href="/about">About Us</a>
            </li>
            <li>
              <a href="/pricing">Pricing</a>
            </li>
          </>
        </ul>
        <div className="navbarCTA">
          <button
            className="navbarCTAButton"
            onClick={() =>
              window.open(`${process.env.REACT_APP_BACKEND_URL}/login`, "_self")
            }
          >
            Log In
          </button>
          <h4>
            {/* <button className="navbarCTAButton">Get started</button> */}
          </h4>
        </div>
      </div>
    </>
  );
};

export default Navbar;
