import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./index.css";
import Protected from "./components/protected/Protected";
import Testimonials from "./components/testimonials/Testimonials";
import Navbar from "./components/common/Navbar";
import Pricing from "./components/pricing/Pricing";
import Footer from "./components/footer/Footer";
import Dashboard from "./components/dashboard/Dashboard";
import Sidebar from "./components/common/Sidebar";
import AuthNavbar from "./components/common/AuthNavbar";
import About from "./components/about-us/About";
import Home from "./components/home/Home";
import Terms from "./components/terms-condition/Terms";
import Contact from "./components/about-us/Contact";
import Profile from "./components/profile/Profile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PostView from "./components/post/PostView";
import FacebookCallback from "./utils/FacebookCallback";
import Platforms from "./components/platforms/Platforms";
import SignIn from "./components/auth/SignIn";
import Custom404 from "./components/miscellaneous/Custom404";
import { useSelector } from "react-redux";
import { ErrorPage } from "./components/miscellaneous/ErrorPage";
import { Suspense } from "react";
import Loader from "./components/loader/Loader";
import Calendar from "./components/calendar/Calendar";
import Teams from "./components/teams/Teams";
import Invoicing from "./components/invoicing/Invoicing";

function App() {
  const auth = useSelector((state) => state.user.auth);

    return (
        <BrowserRouter>
            <Routes>
                {/* Website pages */}
                <Route path="/" element={<Suspense fallback={<Loader />}>
                    <Home />
                </Suspense>} />
                <Route
                    path="/pricing"
                    element={
                        <Suspense fallback={<Loader />}>
                            <Navbar />
                            <Pricing />
                            <Footer />
                        </Suspense>
                    }
                />
                <Route
                    path="/about"
                    element={
                        <Suspense fallback={<Loader />}>
                            <Navbar />
                            <About />
                            <Footer />
                        </Suspense>
                    }
                />
                <Route
                    path="/contact-us"
                    element={
                        <Suspense fallback={<Loader />}>
                            <Navbar />
                            <Contact />
                            <Footer />
                        </Suspense>
                    }
                />
                <Route
                    path="/testimonial"
                    element={
                        <Suspense fallback={<Loader />}>
                            <Navbar />
                            <Testimonials />
                            <Footer />
                        </Suspense>
                    }
                />
                <Route
                    path="/terms"
                    element={
                        <Suspense fallback={<Loader />}>
                            <Navbar />
                            <Terms />
                            <Footer />
                        </Suspense>
                    }
                />
                <Route path="/login" element={<SignIn />} />

                {/* Software pages */}
                <Route
                    path="/dashboard"
                    element={
                        <Suspense fallback={<Loader />}>
                            <Protected>
                                <AuthNavbar />
                                <Sidebar />
                                <Dashboard />
                            </Protected>
                        </Suspense>
                    }
                />

                <Route
                    path="/profile"
                    element={
                        <Suspense fallback={<Loader />}>
                            <Protected>
                                <AuthNavbar />
                                <Profile />
                            </Protected>
                        </Suspense>
                    }
                />

                <Route
                    path="/post/:id"
                    element={
                        <Suspense fallback={<Loader />}>
                            <Protected>
                                <AuthNavbar />
                                <Sidebar />
                                <PostView />
                            </Protected>
                        </Suspense>
                    }
                />

                <Route
                    path="/platforms"
                    element={
                        <Protected>
                            <AuthNavbar />
                            <Sidebar />
                            <Platforms />
                        </Protected>
                    }
                />
                <Route
                    path="/facebook/callback"
                    element={<FacebookCallback />}
                />
                <Route
                    path="/*"
                    element={
                        <>
                            {auth ? <AuthNavbar /> : <Navbar />}
                            <Custom404 />
                        </>
                    }
                ></Route>

                <Route
                    path="/calendar"
                    element={
                        <Protected>
                        <AuthNavbar />
                        <Sidebar />
                        <Calendar />
                        </Protected>
                    }
                    />
                    <Route
                    path="/teams"
                    element={
                        <Protected>
                        <AuthNavbar />
                        <Sidebar />
                        <Teams />
                        </Protected>
                    }
                    />
                    <Route
                    path="/invoicing"
                    element={
                        <Protected>
                        <AuthNavbar />
                        <Sidebar />
                        <Invoicing />
                        </Protected>
                    }
        />

                <Route
                    path="/error-page"
                    element={
                        <>
                            {auth ? <AuthNavbar /> : <Navbar />}
                            <ErrorPage />
                        </>
                    }
                ></Route>
            </Routes>
            <ToastContainer />
        </BrowserRouter>
    );
}

export default App;
