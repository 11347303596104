import "./calendar.css";
import backgroundImage from "../../img/Teams.png";

const Invoicing = () => {
  return (
    <div className="container">
      <div
        className="background"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <div className="text-container">
        <h1 className="title">Invoicing</h1>
        <p className="description">
          Easily create, edit, and send invoices <br /> to clients with just one click.
        </p>
      </div>
    </div>
  );
};

export default Invoicing;
