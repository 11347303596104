import React from 'react'
import { ClipLoader } from 'react-spinners'

function Loader() {
  return (
    <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <ClipLoader
        color="#277dff"
        size={40}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  )
}

export default Loader
