import React, { useState } from 'react';
import styles from './pricing.module.css';
import Card from './Card';
import ToggleButton from 'react-toggle-button'

const cardDetails1 = {
    title: "Personal",
    price: "12,99",
    unit: "/ user",
}
const cardDetailsYearly = {
    title: "Personal",
    price: "12,99000",
    unit: "/ user",
}
const cardDetails2 = {
    title: "Pro",
    price: "12,99",
    unit: "/ user",
    discount: "- 30%"
}
const cardDetails3 = {
    title: "Enterprise",
    price: "54,90",
    unit: "/ user",
}

function PersonalPlanCard() {
    const [state, setState] = useState({
        buttonOn: false
    });
    return (
        <section>
            <div className={styles.pricingSection}>
                <div className={styles.head}>
                <h1>Plans and Pricings</h1>
                <div style={{display:"flex",marginTop:"18px"}}>
                        <span style={{marginRight:"8px"}}>Monthly</span>
                        <ToggleButton
                            inactiveLabel=""
                            activeLabel=""
                            value={state.buttonOn}
                            onToggle={value => {
                                setState({ ...state, buttonOn: !value });
                            }}
                        /><span style={{ marginLeft: "8px" }}>Yearly</span>
                </div>
                
                </div>
                <div className={styles.cardContainers}>
                    <Card cardDetails={!state.buttonOn ? cardDetails1 : cardDetailsYearly} />
                    <Card cardDetails={cardDetails2} />
                    <Card cardDetails={cardDetails3} />
                </div>
            </div>
        </section>


    );
}

export default PersonalPlanCard;    