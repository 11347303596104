import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import LoginComponent from "./LoginComponent";
import SignupComponent from "./SignupComponent";
import "./signin.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getData } from "../../utils/api";
import { updateAuth, updateUserDetails } from "../../redux/feature/user";
import avatarMen from "../../img/avatar_men.jpeg"
import avatarWomen from "../../img/avatar_women.jpg"
import avatar2 from "../../img/agency_men.jpg"


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
};
const handleGoogleAuth = async () => {
    window.open(
        `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
            process.env.REACT_APP_GOOGLE_CLIENT_ID
        }&redirect_uri=${encodeURIComponent(
            `${process.env.REACT_APP_APP_URL}/login`
        )}&response_type=code&scope=${encodeURIComponent(
            "profile email"
        )}&access_type=offline&prompt=consent`,
        "_self"
    );
};

function SignIn() {
    const [searchParams] = useSearchParams();
    const [showLogin, setShowLogin] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.user.auth);
    const error = searchParams.get("err") || searchParams.get("error");
    const code = searchParams.get("code");
    const testimonials = [
        {
            review: "Backdorr has transformed our workflow! It’s so intuitive that our entire team got up to speed in no time, making project collaboration a breeze.",
            name: " Saurabh",
            profession: "Content Creator",
            img:avatarMen
        },
        {
            review: "Backdorr is a game-changer. It’s exactly what this industry has been missing!",
            name: "Anchal Sharma",
            profession: "Linkedin Top Voice",
            img: avatarWomen
        },
        {
            review: "Backdorr interface is so clean and straightforward. It’s helped me reduce the time spent on admin work and focus more on creative aspects of my role.",
            name: "Ayush",
            profession: "Agency owner",
            img: avatar2
        },
    ];

    useEffect(() => {
        if (code) {
            const getGoogleCallback = async () => {
                const response = await getData(`google-auth/callback?code=${code}`);
                if (response.error) {
                    toast.error(response.error);
                } else {
                    toast.success(response.message);
                    dispatch(updateAuth(true));
                    dispatch(updateUserDetails(response.user));
                    navigate('/dashboard');
                }
            };
            getGoogleCallback();
        }
        if (error) {
            toast.error(error);
        }
    }, []);
    
   
    useEffect(() => {
        if (auth != false) {
            navigate("/dashboard");
        }
    }, [auth, navigate]);
    return (
        <section className="authLogin">
            {showLogin ? (
                <LoginComponent
                    handleGoogleAuth={handleGoogleAuth}
                    setShowLogin={setShowLogin}
                />
            ) : (
                <SignupComponent
                    handleGoogleAuth={handleGoogleAuth}
                    setShowLogin={setShowLogin}
                />
            )}
            <div className="authRight">
                <div className="authTestimonial">
                    <Slider {...settings}>
                        {testimonials.map((testimonial,index) => (
                            <div key={index}>
                                <p style={{fontWeight:"500"}}>{testimonial.review}</p>
                                <div className="profileImg">
                                    <img src={testimonial.img}/>
                                </div>
                                <div style={{ marginTop: "24px" }}>
                                    <p className="authTestimonialUser">
                                        {testimonial.name}
                                    </p>
                                    <p className="authTestimonialDesignation">
                                        {testimonial.profession}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
}

export default SignIn;
