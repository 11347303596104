import React, { useState } from "react";
import "./createOrg.css";
import { useSelector, useDispatch } from "react-redux";
import Confetti from "react-confetti";
import { updateOrganisation, updateWorkspace } from "../../redux/feature/user"; // Ensure this action is correctly imported
import { postData } from "../../utils/api";

const CreateOrg = ({ setShowPopup }) => {
  const [organisation, setOrganisation] = useState("Organisation_1");
  const [workspace, setWorkspace] = useState("Workspace_1");
  const [createOrg, setCreateOrg] = useState(true);
  const userDetails = useSelector((state) => state.user.userDetails);
  const dispatch = useDispatch();
  const width = window.outerWidth;
  const height = window.outerHeight;

  const handelSave = async () => {
    const data = await postData("create-organisation", {
      orgName: organisation,
      workspaceName: workspace,
    });

    if (!data.orgs?.length) setShowPopup(true);
    else {
      const orgId = data.orgs[0].id;
      dispatch(
        updateOrganisation({ id: data.orgs[0].id, name: data.orgs[0].name })
      );
      setCreateOrg(false);

      const workspaceData = await postData("create-workspace", {
        orgId,
        workspaceName: workspace,
      });

      dispatch(
        updateWorkspace({ id: workspaceData.id, name: workspaceData.name })
      );
    }
  };

  return (
    <>
      <div className="containerOverlay">
        {!createOrg && <Confetti width={width} height={height} />}
      </div>
      <div className="orgScreen">
        {createOrg ? (
          <div>
            <div>
              <h2 className="orgQuestion">
                What would you like to call your organization?
              </h2>
              <input
                className="orgInput"
                type="text"
                value={organisation}
                onChange={(e) => setOrganisation(e.target.value)}
              />
            </div>
            <div>
              <h2 className="orgQuestion">
                What would you like to call your first workspace?
              </h2>
              <input
                className="orgInput"
                type="text"
                value={workspace}
                onChange={(e) => setWorkspace(e.target.value)}
              />
            </div>

            <div className="orgAction">
              <button className="" onClick={handelSave}>
                Save
              </button>
            </div>
          </div>
        ) : (
          <>
            <div>
              <div className="orgContent">
                <h2 className="orgContentHeading">
                  Congrats {userDetails?.name}! You made it
                </h2>
                <h2 className="orgContentHeading">
                  Welcome to your new organisation
                </h2>
                <ul>
                  <li>Share content with brands and clients</li>
                  <li>Assign content to campaigns</li>
                  <li>Auto post and repurpose content</li>
                  <li>Generate custom captions and hashtags</li>
                </ul>
              </div>

              <div className="orgAction">
                <button className="" onClick={() => setShowPopup(false)}>
                  Start exploring
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CreateOrg;
