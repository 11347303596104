import React from 'react';
import styles from './pricing.module.css';
import checkIconBlack from '../../img/check-black.svg';
import checkIcon from '../../img/check.svg'

const FeatureItem = ({ text, isHighlighted,plan }) => {
    console.log(text, isHighlighted)
    return (
        <div className={styles.featureItem}>
            <img loading="lazy" src={plan=="card2"?checkIcon:checkIconBlack} alt="" className={styles.featureIcon} />
            <div className={isHighlighted ? styles.highlightedText : plan == "card2" ? styles.proFeatureText :styles.featureText}>
                {text}
            </div>
        </div>
    );
};

export default FeatureItem;